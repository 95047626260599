@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

ion-button {
  height: 40px;
  min-height: inherit;
  min-width: 110px;
  position: relative;
  flex-shrink: 0;
  --padding-top: 0;
  --padding-bottom: 1px;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-radius: 20px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: var(--open-sans);
  letter-spacing: .6px;
  /*
  @media(max-width: $phone-h) {
    min-width: inherit;
  }
  */

  .icon {
    height: 24px;
    width: 24px;
    fill: var(--color-var(--color-white));
    flex-shrink: 0;
    transition: opacity var(--transition-250), visibility var(--transition-250);
  }

  .button__text {
    transition: opacity var(--transition-250), visibility var(--transition-250);
  }

  .button__loader {
    opacity: 0;
    visibility: hidden;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: var(--color-white) var(--color-white) var(--color-white) transparent;
    box-sizing: content-box;
    animation: loader 1s infinite linear;
    transition: opacity var(--transition-250), visibility var(--transition-250);
  }

  .button__count {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    min-width: 20px;
    padding: 0 6px;
    position: absolute;
    top: -8px;
    right: -8px;
    color: var(--color-white);
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    background-color: var(--color-primary);
    border-radius: 20px;
  }

  &.is-loading {
    .button__text,
    .icon {
      opacity: 0;
      visibility: hidden;
    }
    .button__loader {
      opacity: 1;
      visibility: visible;
    }
  }

  &[disabled="true"] {
    opacity: 1;
    --ion-color-base: var(--color-gray-500) !important;
    --ion-color-base-rgb: var(--color-gray-500) !important;
    --ion-color-contrast: var(--color-green) !important;
    --ion-color-contrast-rgb: var(--color-gray-500) !important;
    --ion-color-shade: var(--color-gray-500) !important;
    --ion-color-tint: var(--color-gray-500) !important;
    user-select: none;

    &::part(native) {
      color: var(--color-gray-400) !important;
    }

    .icon {
      fill: var(--color-gray-400);
    }

    .button__loader {
      border-color: var(--color-gray-400) var(--color-gray-400) var(--color-gray-400) var(--color-gray-500);
    }
  }
}

.ion-color-tertiary {
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--color-primary);

  &[disabled="true"] {
    --border-color: rgba(255,255,255,0);
  }

  .button__loader {
    border-color: var(--color-primary) var(--color-primary) var(--color-primary) rgba(#680966, .5);
  }
}

.ion-color-back,
.ion-color-icon {
  --ion-color-base: var(--color-primary) !important;
  --ion-color-base-rgb: var(--color-primary) !important;
  --ion-color-contrast: var(--color-green) !important;
  --ion-color-contrast-rgb: var(--color-primary) !important;
  --ion-color-shade: var(--color-primary-100) !important;
  --ion-color-tint: var(--color-primary-100) !important;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--color-var(--color-white));
  font-size: 12px;
  font-weight: 600;

  &::part(native) {
    color: var(--color-var(--color-white));
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}

.ion-color-back {
  &::part(native) {
    color: var(--color-white);
  }

  .icon {
    height: 16px;
    width: 16px;
    fill: var(--color-white);

    &:first-child {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: 6px;
    }
  }
}

.ion-color-back-primary {
  --padding-start: 0;
  --padding-end: 0;
  margin: 0;
  height: auto;
  min-height: inherit;
  min-width: inherit;

  &::part(native) {
    color: var(--color-primary);
    font-weight: 600;
  }

  .icon {
    height: 16px;
    width: 16px;
    margin-top: 1px;
    fill: var(--color-primary);

    &:first-child {
      margin-right: 4px;
    }
    &:last-child {
      margin-left: 4px;
    }
  }
}

.ion-color-back-secondary {
  --padding-start: 0;
  --padding-end: 0;
  margin: 0;
  height: auto;
  min-width: inherit;

  &::part(native) {
    color: var(--color-white);
    font-weight: 600;
  }

  .icon {
    height: 16px;
    width: 16px;
    margin-top: 1px;
    fill: var(--color-white);

    &:first-child {
      margin-right: 4px;
    }
    &:last-child {
      margin-left: 4px;
    }
  }

  &[disabled="true"] {
    --ion-color-base: transparent !important;
    --ion-color-base-rgb: transparent !important;
    --ion-color-contrast: transparent !important;
    --ion-color-contrast-rgb: transparent !important;
    --ion-color-shade: transparent !important;
    --ion-color-tint: transparent !important;

    .icon {
      fill: var(--color-white);
    }

    &::part(native) {
      color: var(--color-white) !important;
    }
  }
}

.ion-color-circle {
  height: 36px;
  min-height: inherit;
  width: 36px;
  min-width: inherit;
  --ion-color-base: var(--color-primary) !important;
  --ion-color-base-rgb: var(--color-primary) !important;
  --ion-color-contrast: var(--color-var(--color-white)) !important;
  --ion-color-contrast-rgb: var(--color-primary) !important;
  --ion-color-shade: var(--color-primary) !important;
  --ion-color-tint: var(--color-primary-100) !important;
  @media(max-width: $phone-h) {
    height: 30px;
    width: 30px;
  }

  .icon {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    fill: var(--color-white);
    @media(max-width: $phone-h) {
      height: 14px;
      width: 14px;
    }
  }
}

.ion-color-link {
  height: auto;
  min-width: inherit;
  margin: 0;
  letter-spacing: 0 !important;
  text-decoration: underline !important;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
  --ion-color-base: transparent !important;
  --ion-color-base-rgb: transparent !important;
  --ion-color-contrast: var(--color-primary) !important;
  --ion-color-contrast-rgb: transparent !important;
  --ion-color-shade: transparent !important;
  --ion-color-tint: transparent !important;

  &::part(native) {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-primary);
  }
}

.ion-color-transparent {
  height: auto;
  min-width: inherit;
  margin: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
  --ion-color-base: transparent !important;
  --ion-color-base-rgb: transparent !important;
  --ion-color-contrast: transparent !important;
  --ion-color-contrast-rgb: transparent !important;
  --ion-color-shade: transparent !important;
  --ion-color-tint: transparent !important;

  &::part(native) {
    overflow: visible;
    color: var(--color-gray-9);
  }
}

.ion-color-icon-only,
.ion-color-icon-only-transparent {
  height: auto;
  min-width: inherit;
  margin: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
  --ion-color-base: transparent !important;
  --ion-color-base-rgb: transparent !important;
  --ion-color-contrast: transparent !important;
  --ion-color-contrast-rgb: transparent !important;
  --ion-color-shade: transparent !important;
  --ion-color-tint: transparent !important;

  &::part(native) {
    overflow: visible;
  }

  .icon {
    fill: var(--color-black);
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}

.ion-color-icon-only-transparent {
  fill: var(--color-white);
}

.ion-color-icon {
  font-size: 12px;
  font-weight: 600;
  .icon {
    height: 18px;
    width: 18px;
    fill: var(--color-white);
  }

  &::part(native) {
    color: var(--color-white);
  }
}

.ion-color-confirm {
  min-width: inherit;
  margin: 0;
  --padding-start: 0;
  --padding-end: 0;
  --ion-color-base: transparent !important;
  --ion-color-base-rgb: transparent !important;
  --ion-color-contrast: var(--color-green) !important;
  --ion-color-contrast-rgb: transparent !important;
  --ion-color-shade: transparent !important;
  --ion-color-tint: transparent !important;
  --border-radius: 0;
  font-size: 13px;

  &::part(native) {
    padding-inline-start: 0;
    padding-inline-end: 0;
    font-size: 13px;
  }
}

.ion-color-delete {
  min-width: inherit;
  margin: 0;
  --padding-start: 0;
  --padding-end: 0;
  --ion-color-base: transparent !important;
  --ion-color-base-rgb: transparent !important;
  --ion-color-contrast: var(--color-red) !important;
  --ion-color-contrast-rgb: transparent !important;
  --ion-color-shade: transparent !important;
  --ion-color-tint: transparent !important;
  --border-radius: 0;

  &::part(native) {
    padding-inline-start: 0;
    padding-inline-end: 0;
    font-size: 13px;
  }
}

.ion-color-popover {
    margin-right: 0;
  --ion-color-base: transparent !important;
  --ion-color-base-rgb: transparent !important;
  --ion-color-contrast: black !important;
  --ion-color-contrast-rgb: transparent !important;
  --ion-color-shade: transparent !important;
  --ion-color-tint: transparent !important;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.ion-color-icon-action {
  height: auto;
  min-width: inherit;
  margin: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
  --ion-color-base: transparent !important;
  --ion-color-base-rgb: transparent !important;
  --ion-color-contrast: transparent !important;
  --ion-color-contrast-rgb: transparent !important;
  --ion-color-shade: transparent !important;
  --ion-color-tint: transparent !important;

  &::part(native) {
    overflow: visible;
  }

  .icon {
    height: 18px;
    width: 18px;
    margin: 0 !important;
  }
}

.button {    
    &-switch {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 44px;
      min-width: inherit;
      --padding-start: 0;
      --padding-end: 0;
      --background: var(--color-white);
      --background-activated: var(--color-white);
      --background-focused: var(--color-white);
      --background-hover: var(--color-white);
      --border-width: 0;
      --border-radius: 50%;
      border-radius: 50%;
      background: var(--color-white);
      box-shadow: 0px 0px 10px rgba(171, 171, 171, 0.33); 
      box-shadow: 0 0 9px rgba(#000, .16);

      &::part(native) {
        height: 44px;
        overflow: visible;
      }

      &__icon {
          height: 24px;
          width: 24px;
          fill: rgba(#000, .16);
          transition: fill var(--transition-250);
      }

      &--on &__icon {
          fill: #EB445A;
      }

      &--off &__icon {
          fill: #7FB439;
      }

      &__text {
        font-size: 12px;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  }

  &-lock {
    height: 44px;
    width: 44px;
    position: relative;
    background-color: var(--ion-color-light);
    transition: background-color var(--transition-250);
    border-radius: 50%;
    --padding-start: 0;
    --padding-end: 0;

    &::part(native) {
      height: 44px;
      overflow: visible;
    }

    &__icon {
      height: 22px;
      width: 22px;
      fill: var(--color-gray-6);
      transition: fill var(--transition-250);
    }

    &__label {
      opacity: 0;
      visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -10px;
      color: var(--color-green);
      font-size: 8px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: var(--color-white);
      box-shadow: 0px 1px 5px rgba(0,0,0,.18);
      transition: opacity 100ms visibility 100ms;
      border-radius: 100%;
    }

    &--on {
      background-color: var(--ion-color-primary);
    }

    &--on &__icon {
      fill: var(--color-white);
    }

    &--on &__label {
      opacity: 1;
      visibility: visible;
    }
  }
}

.control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border: 1px solid var(--color-gray-500);
  background-color: var(--color-gray-500);
  transition: color var(--transition-250), background-color var(--transition-250);
  border-radius: 50%;

  &::part(native) {
      overflow: visible;
      background-color: transparent;
  }

  &__icon {
    height: 22px;
    width: 22px;
    fill: var(--color-gray-25);
    transition: fill var(--transition-250);
  }

  &__text {
    color: var(--color-gray-25);
    font-size: 12px;
    transition: color var(--transition-250);
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.active:not(.unactive) {
    border-color: var(--color-green);
    background-color: #F7FBF1;
  }

  &.active:not(.unactive) &__icon {
    fill: var(--color-green);
  }

  &.active:not(.unactive) + .control-button__text {
    color: var(--color-green);
  }

  &.unactive,
  &[disabled="true"] {
    background-color: var(--color-gray-500);

    &::part(native) {
      cursor: default;
    }
  }

  &.unactive &__icon,
  &[disabled="true"] &__icon {
    fill: var(--color-gray-26);
  }
}

.buttons {
  &-list {
      &__item {
          padding-top: 6px;
          &:first-child {
              padding-top: 0;
          }
      }
  }
}