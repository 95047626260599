/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #680966;
  --ion-color-primary-rgb: 104,9,102;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #5c085a;
  --ion-color-primary-tint: #510950;

  --ion-color-secondary: var(--color-white);
  --ion-color-secondary-rgb: var(--color-primary-200);
  --ion-color-secondary-contrast: var(--color-primary);
  --ion-color-secondary-contrast-rgb: var(--color-primary-200);
  --ion-color-secondary-shade: var(--color-primary-200);
  --ion-color-secondary-tint: var(--color-primary-200);

  --ion-color-tertiary: var(--color-white);
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: var(--color-primary);
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: var(--color-primary-200);
  --ion-color-tertiary-tint: var(--color-primary-200);

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --detail-icon-opacity: 0;
}

:root {
  --border-radius: 100px;
  --ion-margin: calc(var(--padding-start) + var(--ion-safe-area-left, 0px));

  --roboto: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --open-sans: 'OpenSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --ion-font-family: var(--roboto);

  --color-white: #fff;
  --color-black: #000;
  --color-black-100: #171717;
  --color-pale-olive-green: #abdb64;
  --color-leaf: #76a932;
  --color-primary: #680966;
  --color-primary-100: #510950;
  --color-primary-200: #f3eff9;
  --color-gray-400: #92949c;
  --color-gray-500: #f4f5f8;
  --color-gray-600: #f8f8f8;
  --color-gray-700: #dde3e8;
  --color-gray-800: #f5f6f8;

  --color-gray: #8e8e8e;
  --color-gray-2: #e8e8e8;
  --color-gray-3: #e9e9e9;
  --color-gray-4: #e6e6e6;
  --color-gray-5: #989898;
  --color-gray-6: #3b3b3b;
  --color-gray-7: #9f9f9f;
  --color-gray-8: #ededed;
  --color-gray-9: #4a4a4a;
  --color-gray-10: #979797;
  --color-gray-11: #f4f4f4;
  --color-gray-12: #e7e7e7;
  --color-gray-13: #dcdcdc;
  --color-gray-14: #c2c2c2;
  --color-gray-15: #171717;
  --color-gray-16: #e3e3e3;
  --color-gray-17: #dfdfdf;
  --color-gray-18: #d8d8d8;
  --color-gray-19: #6d6d6d;
  --color-gray-20: #f8f9fb;
  --color-gray-21: #e0e0e0;
  --color-gray-22: #9b9b9b;
  --color-gray-23: #b5b6bc;
  --color-gray-24: #E2E5ED;
  --color-gray-25: #8A8C94;
  --color-gray-26: #D8D9DC;
  --color-gray-27: #ECEEEF;
  --color-gray-28: #D9D9DE;
  --color-gray-29: #949596;
  --color-gray-30: #6D6D72;
  --color-red: #eb445a;
  --color-red-2: #ff3d3d;
  --color-green: #84b33a;
  --color-green-200: #7cae2e;
  --color-green-300: #7fb439;
  --color-green-400: #7fb539;
  --color-blue: #4183D9;
  --color-purple: #680966;
  --color-orange: #F0AF43;
  
  --progress-1: linear-gradient(90deg, rgba(0,236,255,1) 0%, rgba(255,130,0,1) 50%, rgba(255,0,0,1) 100%);
  --progress-2: linear-gradient(90deg, rgba(0,236,255,1) 0%, rgba(66,209,189,1) 50%, rgba(21,128,114,1) 100%);


  --ion-color-help: #f3eff9;
  --ion-color-help-rgb: 243,239,249;
  --ion-color-help-contrast: #000000;
  --ion-color-help-contrast-rgb: 0,0,0;
  --ion-color-help-shade: #e6dff0;
  --ion-color-help-tint: #f4f1fa;

  --ion-color-link: transparent;
  --ion-color-link-rgb: 243,239,249;
  --ion-color-link-contrast: #000000;
  --ion-color-link-contrast-rgb: 0,0,0;
  --ion-color-link-shade: transparent;
  --ion-color-link-tint: transparent;

  --transition-125: 125ms ease;
  --transition-250: 250ms ease;
  --transition-500: 500ms ease;

  --box-shadow: 0 1px 12px rgba(0,0,0,.12);
}

$desktop-lg: 1920px;
$desktop-w: 1440px;
$desktop-l: 1350px;
$desktop: 1200px;
$tablet-h: 1025px;
$tablet-v: 768px;
$phone-h: 640px;
$phone-v: 480px;
$phone-s: 359px;
$phone-xs: 320px;