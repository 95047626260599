.group {
    &-list {
        &__item {
            margin-bottom: 16px;
            border-radius: 10px;
            background-color: white;
            box-shadow: var(--box-shadow);
            &:last-child {
                margin-bottom: 0;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            padding: 24px 17px 25px;
            position: relative;

            &-start {
                width: 44px;
                flex-shrink: 0;
            }

            &-middle {
                width: 24px;
                margin: 9px 18px 0 0;
                flex-shrink: 0;
            }

            &-end {
                flex-grow: 1;
                padding-right: 70px;
            }

            &-controls {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }

            &-image {
                width: 100%;
                max-width: 24px;
            }
        }

        &__title {
            color: var(--color-gray-9);
            font-size: 18px;
            font-weight: 300;
            line-height: 22px;
            text-transform: uppercase;
            word-wrap: break-word;
        }

        &__sub-title {
            color: var(--color-gray-22);
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
        }

        &__button {
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: right;

            &-icon {
                height: 20px;
                width: 20px;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-20px, -50%);
                fill: var(--color-green);
            }
        }
    }
}