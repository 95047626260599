.add-device {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        height: 78px;
        width: 78px;
        fill: var(--ion-color-primary);
        margin-bottom: 15px;
    }

    &__title {
        padding-bottom: 5px;
        color: var(--ion-color-primary);
        font-size: 28px;
        font-weight: 300;
        line-height: 34px;
    }

    &__text {
        color: var(--ion-color-medium-shade);
        font-size: 16px;
        line-height: 19px;
    }
}

.device-list {
    &__item {
        display: flex;
        align-items: center;
        margin-top: 16px;
        padding: 16px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;

        &:first-child {
            margin-top: 0;
        }
    }

    &__preview {
        flex-shrink: 0;
        max-width: 123px;
        margin-right: 8px;
    }

    &__content {
        flex-grow: 1;
    }

    &__title {
        font-size: 16px;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: -.23px;
    }
}