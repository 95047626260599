.gray-box {
    margin: 8px 0 22px; 
    border: 1px solid var(--color-gray-700);
    border-radius: 10px;
    
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        padding: 12px 20px;
        margin-bottom: 4px;
        background-color: var(--color-gray-800);
        @media(max-width: $phone-h) {
            padding: 12px;
        }

        &:first-child {
            border-radius: 10px 10px 0 0;
        }

        &:only-child {
            border-radius: 10px;
            margin-bottom: 0;
        }

        &--alt {
            min-height: 51px;
            padding-top: 16px;
            color: var(--color-gray-9);
            font-size: 18px;
            font-weight: 600;
            text-align: center;
        }
    }

    &__body {
        padding: 25px 20px;
        border-radius: 0 0 10px 10px;
        background-color: var(--color-gray-800);

        &:first-child {
            border-radius: 10px;
        }
        &:only-child {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &--transparent {
            background-color: transparent;
        }
    }
}