.picker {
    &-opt {
        height: 51px;
        padding-bottom: 6px;
        font-size: 18px;
        line-height: 1;
    }

    &-toolbar {
        --border-color: var(--color-gray-3);

        &-button {
            .picker {
                &-button {
                    font-size: 15px;
                    font-weight: 400;
                    color: var(--color-blue);

                    &.ion-activated {
                        font-size: 15px;
                        font-weight: 400;
                        color: var(--color-blue);
                    }
                }
            }

            &:last-child {
                .picker {
                    &-button {
                        font-weight: 400 !important;
                    }
                }
            }
        }
    }

    &-above-highlight {
        --border-color: var(--color-gray-3);
    }

    &-below-highlight {
        top: 121px !important;
        --border-color: var(--color-gray-3);
    }
}