ion-select {
    border-width: 0;
    &::part(text) {
        color: var(--color-gray-6);
        font-size: 12px;
        font-weight: 600;
    }

    &[interface="popover"] {
        display: flex;
        padding: 10px 11px 9px;
        font-size: 15px;
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        transition: border-color var(--transition-250);

        &::part(icon) {
            opacity: 1;
            border: 0;
            height: 20px;
            width: 20px;
            margin-left: 6px;
        }

        &.select-expanded {
            border-color: var(--color-primary);
        }
    }

    &[disabled] {
        border-color: var(--color-gray-21);
        user-select: none;
        &::part(text) {
            color: var(--color-gray-300);
        }
    }
}