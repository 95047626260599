ion-alert {
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 19px !important;

    p {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    small {
        font-size: 12px;
        line-height: 16px;
    }

    .alert {

        &-button {
            font-size: 14px !important;
            font-weight: 500;
        }

        &-title {
            color: var(--color-primary) !important;
            margin-top: 0 !important;
            font-size: 15px !important;
            font-weight: 500 !important;
            line-height: 22px !important;
        }

        &-message {
            font-size: 14px !important;
            line-height: 19px !important;
            &:empty {
                display: none !important;
            }
        }

        &-button {
            &-group {
                justify-content: space-between;
            }
        }
    }

    /*
    [aria-checked="true"] {
        .alert {
            &-radio {
                &-label {
                    color: var(--color-blue) !important;
                }

                &-inner {
                    border-color: var(--color-blue) !important;
                }
            }
        }
    }
    */
}