.Collapsible {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
    
    &__trigger {
        display: block;
        padding: 15px 50px 16px 15px;
        position: relative;
        cursor: pointer;
        color: var(--color-gray-9);
        font-size: 18px;
        font-family: var(--open-sans);
        font-weight: 300;
        background-color: var(--color-white);
        box-shadow: var(--box-shadow);
        border-radius: 5px;

        &:after {
            content: '';
            height: 10px;
            width: 19px;
            position: absolute;
            top: 50%;
            right: 19px;
            transform: translateY(-50%);
            transition: transform var(--transition-250);
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgd2lkdGg9IjE5cHgiIGhlaWdodD0iMTBweCIgdmlld0JveD0iMCAwIDE5IDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cGF0aCBkPSJNMTcuMjcxNiAwTDE4LjE3OTIgMC45MzA2NzNMOC45MjkzNSA5Ljk1MTg1TDAgMS4zMTc3NkwwLjkwMzY1OCAwLjM4MzIwMUw4LjkyNTM5IDguMTM5NzhMMTcuMjcxNiAwWiIgdHJhbnNmb3JtPSJtYXRyaXgoMSAtMS43NDg0NTU1RS0wNyAxLjc0ODQ1NTVFLTA3IDEgMCAwKSIgaWQ9IlBhZ2UtMSIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9Im5vbmUiIC8+DQo8L3N2Zz4=");
        }

        &.is-open {
            &:after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &__contentInner {
        padding: 22px;
        background-color: var(--color-gray-800);
        border-radius: 0 0 5px 5px;

        .Collapsible {
            &__contentInner {
                padding: 22px 0 0;
            }
        }
    }

    p {
        margin: 0 0 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}