.dashboard {
    min-height: 100%;
    display: flex;
    background-color: var(--color-gray-500);
    @media(max-width: $tablet-h) {
        position: relative;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1060px;
        margin: 0 auto;
        padding: 0 16px;
        @media(max-width: $phone-h) {
            padding: 0 10px;
        }
    }

    &__aside {
        width: 268px;
        flex-shrink: 0;
        position: relative;
        background-color: var(--color-green-300);
        transition: transform var(--transition-250), opacity var(--transition-250), visibility var(--transition-250);

        @media(max-width: $tablet-h) {
            opacity: 0;
            visibility: hidden;
            width: 306px;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            padding: 72px 0 0;
            transform: translateX(-100%);            
            z-index: 1000;
        }

        &:after {
            content: '';
            width: 100vw;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: var(--color-green-300);
            @media(max-width: $tablet-h) {
                width: 100vw;
                left: 100%;
                background-color: rgba(0,0,0,.56);
                z-index: -1;
            }
        }

        &-inner {
            min-height: 100%;
            position: relative;
            padding: 28px 16px;
            color: var(--color-white);
            z-index: 2;
            @media(max-width: $tablet-h) {
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                overscroll-behavior: contain;
                scrollbar-width: thin;
                scrollbar-face-color: transparent;
                scrollbar-shadow-color: transparent;
                scrollbar-highlight-color: transparent;
                scrollbar-color: white transparent;
                padding: 0 16px;
                background-color: var(--color-green-300);
            }
        }

        .icon {
            fill: var(--color-white);
        }
    }

    &__content {
        flex-grow: 1;
        width: 100%;
        max-width: 100%;
        padding: 34px 0 34px 24px;
        @media(max-width: $tablet-h) {
            padding-left: 0;
            padding: 24px 0 16px;
        }
        &:only-child {
            padding-left: 0;
        }
    }

    &-box {
        padding: 24px 0;
        background-color: var(--color-white);
        box-shadow: 0 2px 9px 0 rgba(0,0,0,.1);
        border-radius: 15px;

        &__header {
            padding: 0 24px 22px;
            border-bottom: 4px solid var(--color-gray-500);
            @media(max-width: $phone-h) {
                padding: 0 12px 22px;
            }
        }

        &__body {
            padding: 24px 24px 0;
            
            &:first-child,
            &:first-of-type {
                padding-top: 0;
            }
            @media(max-width: $phone-h) {
                padding: 24px 12px 0;
            }
        }

        &__title {
            font-size: 16px;
            font-weight: 600;
            color: var(--color-gray-9);
        }

        .react-tabs {
            &__tab {
                &-panel {
                    border: 1px solid var(--color-gray-700);
                    border-radius: 0 0 10px 10px;
                }
            }
        }
    }

    .ion-color-back-secondary {
        @media(max-width: $tablet-h) {
            display: none;
        }
    }
}

.aside {
    &-nav {
        margin-top: 16px;
        position: relative;
        position: sticky;
        top: 16px;
        left: 0;

        &__item {
            padding: 8px 0 8px 4px;
            border-bottom: 1px solid rgba(#f8f8f8, .2);

            &:first-child {
                border-top: 1px solid rgba(#f8f8f8, .2);
            }

            svg {
                height: 24px;
                width: 24px;
                flex-shrink: 0;
                margin: 0 8px 0 0;
                fill: var(--color-white);
            }

            &-inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 11px 16px 10px;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: .6px;

                ion-label {
                    display: flex;
                    align-items: center;
                }

                ion-toggle {
                    &::part(track) {
                        background-color: #74a434;
                    }
                }
            }
        }

        &__button {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 15px 16px 14px;
            color: var(--color-white);
            font-size: 14px;
            font-weight: 600;
            letter-spacing: .6px;
            text-decoration: none;
            border-radius: 10px;
            background-color: transparent;
            transition: color var(--transition-250), background-color var(--transition-250);

            &-icon {
                flex-shrink: 0;
                height: 24px;
                width: 24px;
                margin-right: 9px;
                fill: var(--color-white);
                transition: fill var(--transition-250);
            }

            &:hover,
            &--active {
                color: var(--color-green-400);
                background-color: var(--color-white);
            }

            &:hover &-icon,
            &--active &-icon {
                fill: var(--color-green-400);
            }
        }

        &:first-child {
            margin-top: 0;
        }

        &:first-child &__item {
            &:first-child {
                padding-top: 0;
                border-top-width: 0;
            }
        }
    }
}

.menu-open {
    .dashboard {
        &__aside {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
        }
    }
}

.dashboard-add {
    color: var(--ion-color-medium-shade);
    font-size: 14px;

    &__title {
        margin-bottom: 20px;
        color: var(--ion-color-medium-shade);
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
    }

    &__sub-title {
        margin-bottom: 2px;
        color: var(--color-purple);
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
    }

    &__icon {
        height: 48px;
        width: 48px;
        flex-shrink: 0;
        fill: var(--color-purple);
        margin-bottom: 8px;
    }
}

.dashboard-preloader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.25);
    z-index: 9999;

    &__loader {
        width: 78px;
        height: 78px;
        position: relative;
        margin-bottom: 20px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 78px;
            height: 78px;
            margin-top: -39px;
            margin-left: -39px;
            border-radius: 50%;
            border: 4px solid var(--color-gray-16);
            border-left-color: var(--color-green);
            animation: loading 1s ease infinite;
        }
        &:after {
            z-index: 1113;
            border-color: transparent;
            border-left-color: var(--color-green);
            animation: loading .75s ease infinite;
        }
    }

    &__title {
        color: var(--color-purple);
        font-size: 28px;
        font-weight: 300;
    }
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}