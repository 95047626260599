.offline {
    color: var(--color-gray-25);
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;

    img {
        margin-bottom: 10px;
    }

    p {
        font-size: 18px;
    }
}