.device {
    &-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 300;
        
        &__left,
        &__right {
            display: flex;
            align-items: center;
        }

        &__right {
            padding-left: 32px;
            flex-grow: 1;
        }
    }

    &-progress {
        height: 117px;
        width: 117px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 20px;
        color: var(--color-green);
        font-size: 12px;
        text-transform: uppercase;
        border: 1px solid var(--color-green);
        border-radius: 100%;

        &--red {
            color: var(--color-red);
            border-color: var(--color-red);
        }

        &--gray {
            color: var(--color-gray-21);
            border-color: var(--color-gray-21);
        }

        &__temperature {
            font-size: 29px;
            font-weight: 300;
            line-height: 35px;
        }

        &__button {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 0 -2px -19px 0;
        }

        &__showers {
            display: flex;
            align-items: center;
            padding-bottom: 2px;

            .shower {
                height: 24px;
                width: 24px;
                fill: var(--color-green);

                &--red {
                    fill: var(--color-red);
                }
                &--grey {
                    fill: var(--color-gray-24)
                }

                & + .shower {
                    margin-left: -6px;
                }
            }
        }
    }

    &-status {
        &__item {
            padding-top: 15px;
            color: var(--color-green);
            font-size: 15px;
            font-weight: 300;
            line-height: 17px;
            &:first-child {
                padding-top: 0;
            }
        }

        &__title {
            color: var(--color-gray-19);
            font-size: 11px;
            text-transform: uppercase;

            &--red {
                color: var(--color-red);
            }

            &--gray {
                color: var(--color-gray-21);
            }
        }

        &__mode {
            display: flex;
            align-items: center;

            &--red {
                color: var(--color-red);
            }

            &--gray {
                color: var(--color-gray-21);
            }

            &-icon {
                height: 16px;
                width: 16px;
                min-width: 16px;
                margin-right: 4px;
                fill: var(--color-green);

                &--red {
                    fill: var(--color-red);
                }

                &--gray {
                    fill: var(--color-gray-21);
                }
            }
        }
    }

    &-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 6px 30px;

        &__label {
            display: inline-flex;
            opacity: 0;
            visibility: hidden;
            padding: 3px 10px;
            color: var(--color-red);
            font-size: 13px;
            line-height: 15px;
            border: 1px solid var(--color-red);
            border-radius: 10px;
            transition: opacity var(--transition-250), visibility var(--transition-250);

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    
    &-button {
        height: auto !important;
        font-family: var(--open-sans);
        --border-width: 0;
        --border-radius: 0 !important;
        --color: var(--ion-color-medium-shade);
        --background: transparent;
        --background-hover: transparent;
        --background-activated: transparent;
        opacity: 1;

        &::part(native) {
            overflow: visible;
            background-color: transparent;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 130px;
            white-space: normal;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            width: 44px;
            margin: 0 auto 4px;
            position: relative;
            background-color: var(--color-gray-500);
            transition: background-color var(--transition-250);
            border-radius: 100%;

            &-icon {
                height: 24px;
                width: 24px;
                fill: var(--color-gray-23);
                transition: fill var(--transition-250);
            }

            &-label {
                opacity: 0;
                visibility: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                min-width: 20px;
                padding: 0 4px;
                position: absolute;
                top: 0;
                right: 0;
                margin: -2px -8px 0 0;
                color: var(--color-green);
                font-size: 8px;
                font-weight: 500;
                text-transform: uppercase;
                background-color: white;
                box-shadow: 0px 1px 5px rgba(0,0,0,.18);
                transition: opacity 100ms visibility 100ms;
                border-radius: 20px;
                white-space: nowrap;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 14px;
            transition: color var(--transition-250);
        }

        &--active:not(.unactive) &__icon {
            background-color: var(--color-green);

            &-icon {
                fill: white;
            }

            &-label {
                opacity: 1;
                visibility: visible;
            }
        }

        &--active:not(.unactive) &__text {
            color: var(--color-green);
        }

        &--lock {
            &.device-button--active {
                .device {
                    &-button {
                        &__icon {
                            background-color: var(--color-primary);
                        }
                        &__text {
                            color: var(--ion-color-medium-shade);
                        }
                    }
                }
            }
        }

        &.unactive {
            cursor: default;
            pointer-events: none;

            &::part(native) {
                cursor: default;
            }
        }

        &.unactive &__icon {
            background-color: var(--color-gray-500);

            &-icon {
                fill: var(--color-gray-23);
            }
        }
    }

    &-temperature {
        display: flex;
        align-items: center;
        color: var(--ion-color-danger);
        font-size: 20px;
        font-weight: 300;
        transition: color var(--transition-250);

        &__icon {
            height: 16px;
            width: 16px;
            flex-shrink: 0;
            margin-right: 6px;
            fill: var(--ion-color-danger);
            transition: fill var(--transition-250);
        }

        &--alt {
            font-size: 24px;
        }

        &--off {
            color: var(--ion-color-medium-tint);
        }
        &--off &__icon {
            fill: var(--ion-color-medium-tint);
        }
    }


    &-mode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;
        @media(max-width: $phone-h) {
            flex-direction: column;
            padding: 0;
        }

        &__start {
            flex-shrink: 0;
            padding-right: 48px;
            @media(max-width: $phone-h) {
                padding: 0 0 8px;
            }
        }

        &__end {
            flex-grow: 1;
            @media(max-width: $phone-h) {
                width: 100%;
                text-align: center;
            }
        }

        &__icon {
            height: 118px;
            width: 118px;
        }

        &__title {
            color: var(--color-primary);
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 24px;
            transition: color var(--transition-250);
        }
    }


    &-preview {
        margin: 16px 0 8px;
        &__preview {
            height: 120px;
            width: 120px;
            margin: 8px auto;
            padding: 16px;
            overflow: hidden;
            background-color: var(--color-white);
            border-radius: 100%;

            &-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &__text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: var(--open-sans);
            font-size: 16px;
            font-weight: bold;

            ion-button {
                margin-right: 8px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }


    &-row {
        display: flex;
        justify-content: space-between;
        @media(max-width: $tablet-v) {
            flex-direction: column-reverse;
        }

        &__item {
            display: flex;
            @media(max-width: $tablet-v) {
                &:first-child {
                    display: flex;
                    flex-wrap: wrap;

                    .device-row-item {
                        width: 100%;
                        max-width: 50%;
                        padding: 20px 24px 0 0;

                        &:nth-child(1),
                        &:nth-child(2) {
                            padding-top: 0;
                        }
                    }
                }

                &:last-child {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    padding-bottom: 12px;
                    border-bottom: 1px solid var(--color-gray-27);
                }
            }
        }

        &-item {
            padding-right: 32px;
            &:last-child {
                padding-right: 0;
            }
        }

        &-list {
            display: flex;

            &__item {
                padding-right: 20px;
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    &-state {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 300;
        transition: color var(--transition-250);

        svg {
            flex-shrink: 0;
            margin-right: 4px;
            transition: fill var(--transition-250);
        }

        &--lg {
            font-size: 24px;
        }

        &.unactive {
            color: var(--color-gray-23) !important;

            svg {
                fill: var(--color-gray-23) !important;
            }
        }
    }
}

.program {
    &-status {
        display: flex;
        font-family: var(--open-sans);
        @media(max-width: $phone-h) {
            align-items: center;
            flex-direction: column;
        }

        &__col {
            padding: 35px 40px 19px;
            position: relative;
            @media(max-width: $phone-h) {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            & + & {
                @media(min-width: $phone-h) {
                    &:before {
                        content: '';
                        width: 1px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        background-color: var(--color-gray-21);
                    }
                }
            }
        }

        &__title {
            margin: 0 0 8px;
            color: var(--color-primary);
            font-size: 20px;
            line-height: 31px;
            white-space: nowrap;

            &--primary {
                color: var(--color-leaf);
            }
        }

        &__subtitle {
            margin: 0 0 8px;
            color: var(--color-primary);
            font-size: 16px;
            line-height: 24px;

            &--primary {
                color: var(--color-leaf);
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: -8px;
            color: var(--color-gray-9);
            font-size: 13px;

            &-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 16px 32px 16px 0;

                &:last-child {
                    padding-right: 0;
                    padding-bottom: 0;
                }

                &--primary {
                    .program {
                        &-status {
                            &__list {

                                &-top {
                                    color: var(--color-leaf);
                                }

                                &-icon {
                                    fill: currentColor;
                                }
                            }
                        }
                    }
                }
            }

            &-top {
                display: flex;
                align-items: center;
                padding-bottom: 5px;
                font-size: 32px;
                color: var(--color-primary);
            }

            &-bottom {
                margin: 8px 0 0;

                & + & {
                    margin-top: 0;
                }
            }

            &-icon {
                height: 48px;
                width: 48px;
                margin-right: 4px;
                fill: var(--color-primary);
            }
        }


        &--alt {
            justify-content: center;
        }

        &--alt &__col {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 180px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.target {
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px 8px;
        @media(max-width: $phone-h) {
            justify-content: center;
        }

        &__item {
            padding: 0 8px 8px;
        }
    }

    &__label {
        padding: 3px 11px 4px;
        color: var(--color-green-300);
        font-size: 14px;
        text-align: center;
        border: 2px solid var(--color-green-300);
        border-radius: 5px;
    }
}

.consumption {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    text-align: center;

    &__title {
        color: var(--color-gray-9);
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;

        &--sm {
            font-size: 14px;
        }
    }

    &__value {
        padding: 7px 0;
        color: var(--color-purple);
        font-size: 24px;
        font-weight: 300;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
}

.device-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0 66px;
    text-align: center;

    &__icon {
        margin-bottom: 13px;
        img {
            height: 88px;
            width: 88px;
        }
    }

    &__text {
        color: #482935;
        font-size: 18px;
        font-weight: 300;
        line-height: 21.09px;
    }

    &__link {
        color: #D45069;
        text-decoration: none;
    }

    &__id {
        font-size: 14px;
    }
}