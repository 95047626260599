.calculator-chart {
    &__wrapper {
        position: relative;
        padding-top: 16px;
        margin-left: 16px;
        margin-right: -16px;
        
        &:before {
            content: '';
            width: 1px;
            position: absolute;
            top: 21px;
            bottom: 60px;
            left: 0;
            background-color: var(--color-gray-2);
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            border: solid var(--color-gray-2);
            border-width: 0 1px 1px 0;
            margin: 15px 0 0 -1px;
            padding: 4px;
            transform: rotate(-135deg);
            transform-origin: center bottom;
        }
    }

    &__yaxis {
        position: absolute;
        top: 0;
        left: 0;

        &-value {
            position: absolute;
            top: 0;
            left: 50%;
            color: var(--color-green-2);
            font-size: 14px;
            line-height: 1;
            transform: translateX(-50%);
        }
    }

    [fill="url(#fillGradient)"] {
        fill: #f3f4f2;
    }
}