.card {
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: -16px;

        &__item {
            width: 100%;
            max-width: 50%;
            padding: 16px;
            @media(max-width: 768px) {
                max-width: inherit;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            padding: 22px 20px 22px !important;
            position: relative;
            font-size: 16px;
            font-weight: 300;
            line-height: 17px;
            border-bottom: 1px solid var(--color-gray-21);
            &:last-child {
                border-bottom-width: 0;
            }

            &-left {
                display: flex;

                > div {
                    &:first-child {
                        flex-shrink: 0;
                        width: 70px;
                        margin-right: 14px;
                    }
                }
            }

            &-right {
                padding: 14px 0 0 15px;
            }

            &-button {
                height: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(255,255,255,0);

                &-icon {
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                }
            }
        }

        &__content {
            flex-grow: 1;
            padding: 18px 20px !important;
        }

        &__footer {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-end;
            padding: 0 20px 18px;
        }

        &__title {
            padding-bottom: 3px;
            color: var(--color-gray-9);
            font-size: 17px;
            text-transform: uppercase;
            word-wrap: break-word;
            &:last-child {
                padding-bottom: 0;
            }
        }

        &__sub-title {
            padding-bottom: 10px;
            color: var(--color-gray-22);
            &:last-child {
                padding-bottom: 0;
            }
        }

        &__label {
            display: inline-flex;
            align-items: center;
            padding: 2px 6px;
            color: var(--color-green);
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
            border: 1px solid var(--color-green);
            border-radius: 3px;

            .shower {
                height: 16px;
                width: 16px;
                fill: var(--color-green);

                &--red {
                    fill: var(--color-red);
                }
                &--grey {
                    fill: var(--color-gray-24)
                }

                & + .shower {
                    margin-left: -4px;
                }
            }
        }

        &__group {
            &-title {
                padding-bottom: 18px;
                color: var(--color-gray-19);
                font-size: 18px;
                font-weight: 300;
                font-family: var(--open-sans);
            }
        }
    }
}

ion-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    margin: 0;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
}