.react-tabs {
    &__tab {
        flex: 1;
        /*max-width: 33.3333%;*/
        padding: 16px 12px;
        cursor: pointer;
        color: var(--color-gray-6);
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        border-left: 1px solid var(--color-white);
        background-color: var(--color-gray-24);
        transition: color var(--transition-250), background-color var(--transition-250);

        @media(max-width: $phone-h) {
            flex: auto;
            width: 50%;
            max-width: inherit;
            font-size: 14px;
            line-height: 18px;
            border-top: 1px solid var(--color-white);

            &:nth-child(1),
            &:nth-child(2) {
                border-top-width: 0;
            }

            &:nth-child(2n+1) {
                border-left-width: 0;
            }
        }

        &:first-child {
            border-left-width: 0;
            @media(min-width: $phone-h) {
                border-top-left-radius: 10px;
            }
        }
        &:last-child {
            @media(min-width: $phone-h) {
                border-top-right-radius: 10px;
            }
        }

        &--selected {
            color: var(--color-white);
            background-color: var(--color-primary);
        }

        &-list {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            @media(max-width: $phone-h) {
                flex-wrap: wrap;
            }
        }

        &-panel {
            display: none;
            padding: 32px;
            color: var(--color-black-100);
            text-align: left;
            background-color: var(--color-white);
            border-radius: 0 0 10px 10px;
            @media(max-width: $phone-h) {
                padding: 32px 16px;
            }

            &--selected {
                display: block;
            }
        }
    }

    &__box {
        background-color: var(--color-white);
        border-radius: 0 10px 10px 10px;
        box-shadow: var(--box-shadow);
        @media(max-width: $phone-h) {
            border-top-right-radius: 0;
        }

        &-top {
            padding: 32px 16px 0;

            ion-label {
                color: var(--color-primary);
            }
        }

        .react-tabs {
            &-panel {
                background-color: transparent;
            }
        }
    }

    &--alt {
        .Collapsible {
            border: 1px solid var(--color-gray-700);
            border-radius: 10px;

            &__trigger {
                padding-left: 50px;
                text-align: center;
                font-weight: 600;
                box-shadow: none;
                background-color: var(--color-gray-800);
                border-radius: 10px 10px 0 0;
                transition: border-radius 300ms;

                &.is-closed {
                    border-radius: 10px;
                }
            }

            &__contentInner {
                background-color: transparent;
            }
        }   
    }

    &--mod {
        .react-tabs {
            &__tab {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 54px;
                padding: 8px 12px;

                @media(max-width: $phone-h) {
                    border-top-width: 0;
                    border-left-width: 1px;
                    &:first-child {
                        border-left-width: 0;
                        border-top-left-radius: 10px;
                    }
                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }

                &-list {
                    @media(max-width: $phone-h) {
                        flex-wrap: nowrap;
                    }
                }
            }
        }
    }
}