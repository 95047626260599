.offline {
    color: var(--color-gray-22);
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;

    img {
        margin-bottom: 10px;
    }

    p {
        font-size: 18px;
    }

    figure {
        margin: 0 10px;
        display: flex;
        align-items: center;

        img {
            flex-shrink: 0;
            margin: 0 10px 0 0;
        }
    }
}