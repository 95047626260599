.navigation {

    &-buttons {
        display: flex;
        justify-content: space-between;

        ion-button {
            height: auto;
            opacity: 1;
        }
    }

    &-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &__icon {
            height: 24px;
            width: 24px;
            margin-bottom: 4px;
            fill: var(--ion-color-medium-shade);
            transition: fill var(--transition-250);
        }

        &__label {
            color: var(--ion-color-medium-shade);
            font-size: 12px;
            line-height: 19px;
            transition: color var(--transition-250);
        }

        &__badge {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 16px;
            color: white;
            font-size: 11px;
            font-weight: 500;
            line-height: 1;
            background-color: var(--ion-color-primary);
            border-radius: 50%;
        }
    }
}

.active {
    .navigation {
        &-button {
            &__icon {
                fill: var(--color-green);
            }
            &__label {
                color: var(--color-green);
            }
        }
    }
}