.app-footer {
    font-size: 12px;
    font-weight: 300;
    color: var(--color-gray-15);
    background-color: var(--color-white);
    user-select: none;

    ion-toolbar {
        max-width: 1330px;
        margin: auto;
        --padding-top: var(--ion-padding-start, 14px);
        --padding-bottom: var(--ion-padding-start, 14px);
        --padding-start: var(--ion-padding-start, 16px);
        --padding-end: var(--ion-padding-start, 16px);
        background: transparent;        
    }
}