.manuals {
    &-list {
        &__item {
            height: auto;
            font-size: 18px;
            font-weight: 300;
            border-bottom: 1px solid #eee;

            &::part(native) {
                --min-height: 34px;
                --padding-top: 12px;
                --padding-bottom: 12px;
                padding-left: 17px;
                padding-right: 17px;
            }

            &::part(detail-icon) {
                display: none;
            }

            ion-icon {
                height: 16px;
            }

            img {
                flex-shrink: 0;
                max-width: 123px;
                margin-right: 8px;
            }
        }

        &__icon {
            height: 24px;
            width: 24px;

            &--md {
                height: 32px;
                width: 32px;
            }
        }
    }
}