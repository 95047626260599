.welcome {
    min-height: 100%;
    display: flex;
    justify-content: space-evenly;
    background-image: linear-gradient(214deg, var(--color-pale-olive-green) 0%, var(--color-leaf) 100%);
    @media(max-width: 768px) {
        flex-direction: column;
    }

    &__start {
        width: 100%;
        max-width: 40%;
        overflow: hidden;
        user-select: none;
        @media(max-width: 768px) {
            max-width: inherit;
        }

        &-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            clip-path: ellipse(100% 155% at 0% 50%);
            @media(max-width: 768px) {
                clip-path: ellipse(100% 100% at 50% 0%);
            }
        }
    }

    &__end {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 48px;
        color: var(--color-white);
        text-align: center;
        @media(max-width: 1024px) {
            padding: 32px;
        }

        a {
            color: white;
            text-decoration: underline;
        }
        
        &-inner {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            width: 100%;
            max-width: 626px;
            @media(max-width: 768px) {
                max-width: inherit;
            }

            &-top {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 1;
                padding-bottom: 24px;
                &:last-child {
                    padding-bottom: 0;
                }
            }

            &-bottom {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &__title {
        margin: 0;
        padding: 0;
        font-size: 38px;
        line-height: 46px;
        font-weight: 600;
    }

    &__sub-title {
        margin: 0;
        padding: 0;
        font-size: 20px;
        line-height: 26px;
        font-weight: 300;
    }

    &--alt {
        @media(max-width: 1024px) {
            flex-direction: column;
        }
    }

    &--alt &__start {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 62px;
        clip-path: ellipse(100% 100% at 0% 50%);
        color: var(--color-gray-6);
        background-color: var(--color-gray-500);
        @media(max-width: 1024px) {
            max-width: inherit;
            display: block;
            clip-path: ellipse(100% 100% at 50% 0%);
            text-align: center;
        }
        @media(max-width: 640px) {
            clip-path: ellipse(130% 100% at 50% 0%);
            padding: 40px;
        }
        @media(min-width: 1200px) {
            min-width: 640px;
        }
        @media(min-width: 1440px) {
            clip-path: ellipse(100% 150% at 0% 50%);
        }

        &-image {
            height: auto;
            width: auto;
            clip-path: none;
            margin-bottom: 40px;
            @media(max-width: 640px) {
                max-width: 120px;
                margin-bottom: 28px;
            }
        }

        &-title {
            margin-bottom: 20px;
            font-size: 31px;
            line-height: 42px;
            letter-spacing: -.51px;
            &:last-child {
                margin-bottom: 0;
            }
            @media(max-width: 640px) {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &-sub-title {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 300;
            line-height: 36px;
            letter-spacing: -0.4px;
            &:last-child {
                margin-bottom: 0;
            }
            @media(max-width: 640px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &--alt &__end {
        text-align: left;
        &-inner {
            max-width: 620px;
            @media(max-width: 1024px) {
                margin: 0 auto;
            }
        }
    }
}

.download-app {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -12px 0 36px;
    justify-content: center;

    &:last-child {
        margin-bottom: 0;
    }
    @media(max-width: 640px) {
        max-width: 310px;
        margin-left: auto;
        margin-right: auto;
    }

    &__item {
        padding: 12px 12px 0 0;
        &:last-child {
            padding-right: 0;
            @media(max-width: 640px) {
                padding-right: 12px;
            }
        }

        a {
            display: block;

            img {
                vertical-align: middle;
            }
        }
    }
}

.tesy-model {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
    @media(max-width: 640px) {
        display: block;
        text-align: center;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__cont {
        flex-grow: 1;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.33;
        @media(max-width: 640px) {
            font-size: 21px;
            line-height: 32px;
        }
    }

    &__preview {
        height: 120px;
        width: 120px;
        flex-shrink: 0;
        margin-left: 16px;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
        @media(max-width: 640px) {
            margin: 20px auto 0;
        }

        &-image {
            height: 120px;
            width: 120px;
            object-fit: cover;
        }
    }
}

.tesy-models {
    &-list {
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.33;
        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin: 0;
        }

        @media(max-width: 640px) {
            text-align: center;
            font-size: 21px;
            line-height: 32px;
        }
    }

    display: flex;
    align-items: center;
    margin-bottom: 36px;
    &:last-child {
        margin-bottom: 0;
    }

    @media(max-width: 640px) {
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        margin: 12px 12px 0 0;
        &:last-child {
            margin-right: 0;
        }

        @media(max-width: 640px) {
            margin: 0;
            padding: 12px 6px 0;
            text-align: center;
        }
    }

    &__preview {
        height: 120px;
        width: 120px;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);

        &-image {
            height: 120px;
            width: 120px;
            object-fit: cover;
        }
    }
}