.form {

    &-list {
        &__item {
            --inner-padding-top: 23px;
            --inner-padding-bottom: 8px;
            --padding-start: 16px;
            --min-height: inherit;
            --border-color: var(--color-gray-8);
        }
    }

    &-label {
        display: inline-flex;
        margin: 0 !important;
        padding: 0 0 7px !important;
        font-size: 13px !important;
    }

    &-input {
        flex-grow: 1;
        --padding-start: 11px !important;
        --padding-end: 11px !important;
        --color: var(--color-gray-6);
        cursor: default !important;
        font-size: 15px;
        font-weight: 300;
        border: 1px solid var(--color-gray-21);
        border-radius: 8px;
        position: unset;
    }

    &-password {
        width: 100%;
        position: relative;

        &__button {
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: 1px;
            transform: translateY(-50%);
            background-color: transparent;
            outline: none;
            z-index: 2;

            &-icon {
                height: 16px;
                width: 16px;
                fill: var(--color-gray-7);
            }
        }

        .form-input {
            padding-right: 33px !important;
        }
    }

    &-edit {
        width: 100%;
        display: flex;
        align-items: center;

        .form {
            &-input {
                padding-left: 11px;
                padding-right: 11px;
            }
        }
    }

    &-checkbox {
        display: flex !important;
        overflow: visible !important;
        position: relative;
        margin: 0;
        --color: var(--color-gray-9) !important;
        font-size: 14px !important;
        font-weight: 300 !important;
        line-height: 17px;
        white-space: normal !important;

        & + & {
            margin-top: 14px;
        }

        &__element {
            height: 24px;
            width: 24px;
            flex-shrink: 0;
            box-sizing: content-box;
            margin: 0;
        }

        &__text {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            align-items: center;
            padding-left: 12px;
            &:empty {
                padding-left: 0;
            }
        }

        ion-button {
            margin: 0;
            font-size: unset;
            --border-radius: 0;
        }
    }

    &-radio {
        display: inline-flex !important;
        overflow: visible !important;
        position: relative;
        --color: var(--color-gray-9) !important;
        font-size: 14px !important;
        font-weight: 300 !important;
        line-height: 17px;
        white-space: normal !important;

        & + & {
            margin-left: 8px;
        }

        &__element {
            height: 22px;
            width: 22px;
            flex-shrink: 0;
            border-radius: 22px;
            border: 1px solid var(--color-gray-400);
            box-sizing: content-box;
            transition: border-color var(--transition-250);

            &::part(mark) {
                opacity: 0;
                height: 12px;
                width: 12px;
                border: 0;
                background-color: var(--color-green-400);
                border-radius: 12px;
                transition: opacity var(--transition-250);
            }

            &[disabled] {
                border-color: var(--color-gray-400);
            }

            &.radio-checked {
                &::part(mark) {
                    opacity: 1;
                }
            }

            &.radio-disabled {
                border-color: var(--color-gray-400);
            }

            &.radio-disabled.radio-checked {
                border-color: var(--color-gray-400);
                &::part(mark) {
                    opacity: .25;
                    background-color: var(--color-gray-400);
                }
            }
        }

        &__text {
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding-left: 12px;
            &:empty {
                padding-left: 0;
            }
        }

        ion-button {
            margin: 0;
            font-size: unset;
            --border-radius: 0;
        }
    }

    &-toggle {
        display: inline-flex;
        align-items: center;
        user-select: none;

        &__label {
            font-size: 14px;
            font-family: var(--open-sans);
            transition: color var(--transition-250);

            &:last-child {
                color: var(--color-gray-21);
            }
        }

        &__element {
            height: 32px;
            width: 58px;
            margin: 0 6px;
            --background-checked: var(--color-green-300);

            &::part(track) {
                height: 32px;
                width: 100%;
            }
        }

        &--active &__label {
            &:first-child {
                color: var(--color-gray-21);
            }
            &:last-child {
                color: var(--color-gray-6);
            }
        }
    }
}

.PhoneInput {
    display: flex;
    align-items: center;
    padding: 8px;
    --color: var(--color-gray-6);
    font-size: 15px;
    font-weight: 300;
    box-shadow: none;
}

.PhoneInputCountry {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.PhoneInputCountrySelect {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0;
    background-color: transparent;
    border-radius: 0;
}

.PhoneInputCountryIcon {
    display: none;
}

.PhoneInputInput {
    width: 100%;
    --color: var(--color-gray-6);
    font-size: 15px;
    font-weight: 300;
    box-shadow: none;
    appearance: none;
    border: 0;
    border-radius: 0;
}

.css-yk16xz-control,
    .css-1pahdxg-control {
        display:  flex !important;
        flex-wrap: nowrap !important;
        cursor: pointer !important;
        border-radius: 8px !important;
        border: 1px solid var(--color-gray-21) !important;
        box-shadow: none !important;
    }
    
    .css-g1d714-ValueContainer {
        min-height: 40px !important;
        min-width: 30px !important;
        padding-top: 0 !important;
    }
    
    .css-1okebmr-indicatorSeparator {
        display: none;
    }
    
    .css-tj5bde-Svg {
        fill: var(--color-black-100) !important;
        fill-opacity: .75 !important;
    }
    
    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue {
        color: var(--color-gray-6);
        font-family: var(--open-sans) !important;
        font-weight: 300 !important;
        font-size: 15px !important;
    }
    
    .css-yt9ioa-option,
    .css-1n7v3ny-option {
        padding: 8px 12px 10px !important;
        cursor: pointer !important;
        color: var(--color-black-100) !important;
        font-size: 15px !important;
        font-family: var(--open-sans);
        font-weight: 300 !important;
        background-color: transparent !important;
    
        &:first-child {
            border-radius: 8px 8px 0 0;
        }
    
        &:last-child {
            border-radius: 0 0 8px 8px;
        }
    }
    
    .css-1n7v3ny-option {
        color: var(--color-green-300) !important;
    }
    
    .css-9gakcf-option {
        padding: 8px 12px 10px !important;
        cursor: pointer !important;
        color: var(--color-green-300) !important;
        background-color: var(--color-gray-500) !important;
        font-size: 15px !important;
        font-family: var(--open-sans);
        font-weight: 300 !important;
    
        &:first-child {
            border-radius: 8px 8px 0 0;
        }
    
        &:last-child {
            border-radius: 0 0 8px 8px;
        }
    }
    
    .css-1a4e434-control {
        box-shadow: none !important;
    }
    
    .css-16a6pc6-menu {
        overflow: hidden !important;
    }
    
    .css-4ljt47-MenuList {
        padding: 0 !important;
    }
    
    .css-26l3qy-menu {
        border: 1px solid var(--color-gray-21) !important;
        border-radius: 8px !important;
        box-shadow: 0px 2px 9px rgba(0,0,0,.15) !important;
    }
    
    .css-b8ldur-Input {
        input {
            padding-top: 2px !important;
            color: var(--color-black-100) !important;
            font-size: 15px !important;
            font-family: var(--open-sans);
            font-weight: 300 !important;
        }
    }

.language-selector {
    min-width: 70px;

    [class*="ValueContainer"] {
        padding-right: 0 !important;
    }

    [class*="control"] {
        border-radius: 20px !important;
        border-color: var(--color-primary) !important;
    }

    [class*="singleValue"] {
        font-family: var(--open-sans) !important;
        font-weight: 300 !important;
        font-size: 15px !important;
    }
}

.react-select {
    flex-shrink: 0;
    flex-grow: 1;
    
    &--secondary {
        .css-g1d714-ValueContainer {
            min-height: 36px !important;
        }

        .css-yk16xz-control,
        .css-1pahdxg-control {
            border-color: var(--color-green-300) !important;
            background-color: var(--color-green-300) !important;
            border-radius: 10px !important;
        }

        .css-1uccc91-singleValue {
            color: var(--color-white) !important;
            font-size: 12px !important;
        }

        .css-tj5bde-Svg {
            fill: var(--color-white) !important;
            fill-opacity: 1 !important;
        }

        .css-yt9ioa-option, 
        .css-1n7v3ny-option,
        .css-9gakcf-option {
            font-size: 12px !important;
        }

        .css-b8ldur-Input {
            input {
                padding-top: 3px !important;
                color: var(--color-white) !important;
                font-size: 12px !important;
            }
        }
    }
}

.inline-select {
    position: relative;
    color: var(--color-green-300);
    font-size: 13px;

    ion-select {
        min-height: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        border: 0;
        background-color: transparent;

        &::part(container) {
            height: 0;
            width: 0;
            overflow: hidden;
        }
        &::part(text) {
            height: 0;
            width: 0;
            overflow: hidden;
        }
    }
}