ion-popover {    
    ion-list-header {
        min-height: inherit;
        padding: 8px 0 0;
        font-size: 18px;
        font-weight: 500;
    }

    ion-item {
        font-weight: 300;
        border-bottom: 1px solid var(--color-gray-21);

        &:last-child {
            border-bottom-width: 0;
            &::part(native) {
                padding-bottom: 3px;
            }
        }

        &::part(native) {
            min-height: inherit;
            padding: 14px 0 14px 4px;
        }

        &::part(detail-icon) {
            display: none;
        }

        svg {
            height: 16px;
            width: 16px;
            margin: 0 8px 0 0;
        }
    }

    &.select-popover {

        .popover {
            &-content {
                padding: 1px 0;
                border-radius: 10px;
                box-shadow: 0 2px 9px 0 rgba(0,0,0,.14);
            }
            &-arrow {
                filter: drop-shadow(0 0 4px #dadada);
            }
        }

        ion-item {
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            border-width: 0;

            &::part(native) {
                padding: 12px 24px;
                transition: color var(--transition-250), background-color var(--transition-250);

                &:hover{
                    color: var(--color-green-200);
                    background-color: var(--color-gray-500);
                }
            }
        }

        ion-radio {
            margin: 0 0 0 16px;
            --color-checked: var(--color-green-300);
        }

        ion-backdrop {
            background-color: transparent;
        }
    }
}

.popover-wrapper {
    --width: inherit;
}

.popover-content,
.popover .popover-content {
    padding: 16px;
    color: var(--color-gray-6);
    font-size: 13px;
    line-height: 1.25;

    p {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}