
.range {

    &-circle {
        height: 260px;
        width: 260px;
        margin: 0 auto 24px;
        position: relative;
        border: 1px solid var(--color-gray-11);
        background-color: white;
        border-radius: 100%;
        box-shadow: inset 0 0 21px var(--color-gray-12);

        &__didgits {
            height: 202px;
            width: 202px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: inset 0 0 21px var(--color-gray-12);
            border-radius: 100%;
        }

        &__didgit {
            position: absolute;
            color: var(--color-gray-14);
            font-size: 14px;
            line-height: 1;

            &--0 {
                top: 180px;
                left: 22px;
                transform: rotateZ(42deg);
            }

            &--5 {
                top: 50%;
                left: -8px;
                transform: rotateZ(-90deg) translateY(-50%);
            }

            &--10 {
                top: 22px;
                left: 7px;
                transform: rotateZ(-50deg);
            }

            &--15 {
                top: -18px;
                left: 50%;
                transform: translateX(-50%);
            }

            &--20 {
                top: 22px;
                right: 7px;
                transform: rotateZ(50deg);
            }

            &--25 {
                top: 50%;
                right: -11px;
                transform: rotateZ(96deg) translateY(-50%);
            }

            &--30 {
                top: 180px;
                right: 22px;
                transform: rotateZ(139deg);
            }
        }

        &__inner {
            height: 176px;
            width: 176px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 300;
            background-color: white;
            border: 1px solid var(--color-gray-3);
            box-shadow: 0 0 8px var(--color-gray-13);
            border-radius: 100%;
            transition: box-shadow var(--transition-250);
            z-index: 3;
        }

        &__dot{
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: var(--color-red);
            margin: 0 auto 0 30px;
            transition: background var(--transition-250);
    
            &-handle {
                position: absolute;
                top: 50%;
                right: 50%;
                height: 10px;
                margin-top: -5px;
                width: 50%;
                transition: var(--transition-500);
                transform-origin: center right;
                backface-visibility: hidden;
                z-index: 2;

                &--current {
                    z-index: 1;

                    .range {
                        &-circle {
                            &__dot {
                                background: black;
                            }
                        }
                    }
                }
            }
        }

        &__icon {
            height: 22px;
            width: 22px;
            fill: var(--color-red);
            transition: fill var(--transition-250);
        }

        &__temperature {
            padding: 11px 0 7px;
            color: var(--color-red);
            font-size: 44px;
            font-weight: 300;
            line-height: 1;
            transition: color var(--transition-250);
        }

        &__label {
            padding-bottom: 12px;
            color: var(--color-gray-6);
            font-size: 14px;
            font-weight: 300;
            line-height: 1;
            letter-spacing: -.16px;
            transition: color var(--transition-250);
        }

        &__status {
            display: flex;
            align-items: center;
            color: var(--color-red);
            font-size: 18px;
            line-height: 1;
            transition: color var(--transition-250);
        }

        &__option {
            color: var(--color-green-300);
            font-size: 18px;
            font-weight: 300;

            &-button {
                display: inline-flex;
                align-items: center;
                padding: 0;
                color: var(--color-green-300);
                font-size: 18px;
                font-weight: 300;
                background-color: transparent;

                &-icon {
                    flex-shrink: 0;
                    height: 12px;
                    width: 12px;
                    margin-left: 5px;
                    fill: #92949C;
                }
            }
        }

        &--heating &__inner {
            box-shadow: 0 0 13px rgba(#ff3d3d, .25);
        }

        &__timer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
            font-size: 18px;
            font-weight: 100;
            color: var(--color-gray-22);

            &-icon {
                height: 11px;
                width: 11px;
                flex-shrink: 0;
                margin: 0 6px 1px 0;
                fill: var(--color-gray-22);
            }
        }

        &__metric {
            color: var(--color-gray-6);
            font-size: 26px;
            font-weight: 200;
        }

        &__value {
            padding: 17px 0 7px;
            color: var(--color-purple);
            font-size: 40px;
            font-weight: 300;
            line-height: 1;

            &--alt {
                padding: 5px 0 0;
                font-size: 18px;
                color: var(--color-green);
            }
        }

        &.disabled {
            .range {
                &-circle {
                    &__inner {
                        box-shadow: 0 0 8px var(--color-gray-13);
                    }
                    &__temperature {
                        color: var(--color-gray-21);
                    }

                    &__label,
                    &__status {
                        color: var(--color-gray-23);
                    }
                    &__icon {
                        fill: var(--color-gray-23);
                    }
                    &__dot {
                        background: var(--color-gray-22);
                    }
                }
            }
        }

        &--ready {
            .range {
                &-circle {
                    &__temperature,
                    &__status {
                        color: var(--color-green);
                    }
                    &__icon {
                        fill: var(--color-green);
                    }
                    &__dot {
                        background: var(--color-green);
                    }
                }
            }
        }

        &--alt {
            height: auto;
            width: 100%;
            margin: auto;
            border: none;
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;

            .range {
                &-circle {
                    &__inner {
                        height: auto;
                        width: 100%;
                        padding: 25px 20px;
                        position: relative;
                        top: 0;
                        left: 0;
                        transform: none;
                        border: none;
                        border-radius: 10px;
                        background: var(--color-white);
                        box-shadow: 0px 1px 12px rgba(0,0,0,.12);
                    }
                }
            }
        }
    }


    &-slider {
        display: block !important;
        width: auto !important;
        margin: 0 auto;
        cursor: grab;
        --thumb-size: 40px;
        --box-shadow: 0 1px 5px rgba(0,0,0,.18);

        &__wrapper {
            min-height: 65px;
        }

        .MuiSlider {
            &-track {         
                height: 3px;
                background: var(--progress-2);
                background-color: transparent !important;
                //background-size: 400px auto;
                background-repeat: no-repeat;
                border-radius: 3px;
            }
            &-rail {
                height: 3px;
                background-color: var(--color-gray-18);
                border-radius: 3px;
            }
            &-thumb {
                height: 40px;
                width: 40px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                background-color: white;
                box-shadow: var(--box-shadow);
                cursor: grab;
                
                &:hover,
                &.Mui-focusVisible,
                &.MuiSlider-active {
                    cursor: grabbing;
                    box-shadow: var(--box-shadow);
                }

                &.Mui-disabled {
                    height: 40px;
                    width: 40px;
                    margin: 0;
                }
            }
            &-valueLabel {
                width: auto;
                min-width: var(--size);
                position: relative !important;
                transform: scale(1) !important;
                transition: transform var(--transition-125);
                top: 0;
                left: auto;
                
                .PrivateValueLabel-circle-1,
                .PrivateValueLabel-circle-2,
                .PrivateValueLabel-circle-3,
                .PrivateValueLabel-circle-4 {
                    width: auto;
                    min-width: var(--size);
                    padding-left: 4px;
                    padding-right: 4px;
                    transform: none;
                    border-radius: 0;
                    background: transparent;
                }

                .PrivateValueLabel-label-5 {
                    transform: none;
                    font-size: 14px;
                    color: var(--color-gray-9);
                }
            }
        }

        &--temperature {
            .MuiSlider {
                &-track {
                    background-image: var(--progress-1);
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 9px;

            &-item {
                color: var(--color-gray-9);
                font-size: 16px;
                transition: color var(--transition-250);
            }

            &-label {
                color: var(--color-gray-6);
                font-size: 13px;
                font-family: var(--open-sans);
                font-weight: 600;
                transition: color var(--transition-250);
            }

            &.disabled * {
                color: var(--color-gray-23);
            }
        }

        &.Mui-disabled {
            .MuiSlider {
                &-rail {
                    background-color: #ddd;
                }

                &-track {
                    background-color: #9d9fa6 !important;
                    background-image: none;
                }
            }
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-between;

        &__item {
            width: 100%;
            max-width: 50%;
            padding: 0 6px;

            ion-button {
                width: 100%;
            }
        }
    }

    &-options {
        display: flex;
        flex-wrap: wrap;
        margin: -8px -8px 26px;
        &:last-child {
            margin-bottom: 0;
        }

        &__item {
            width: 100%;
            max-width: 50%;
            padding: 8px;
        }

        &__button {
            width: 100%;
            padding: 11px;
            color: var(--color-gray-9);
            font-size: 14px;
            border: 2px solid #bfbdbd;
            border-radius: 5px;
            background-color: transparent;

            &.active {
                background-color: rgba(127,180,57,.2);
                border-color: var(--color-green-300);
            }
        }
    }
}