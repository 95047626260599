.app-header {
    min-width: 375px;
    background-color: var(--color-white);
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.1);
    user-select: none;

    &__logo {
        @media(max-width: $phone-v) {
            max-width: 100px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1330px;
        margin: auto;
        padding: 5px 0 5px 8px;
    }    
}

.menu-button {
    height: 24px;
    width: 24px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    &__line {
        display: block;
        height: 2px;
        width: 24px;
        background-color: var(--color-black);
        transition: transform var(--transition-125), opacity var(--transition-125);

        &:nth-child(1) {
            transform-origin: 0% 0%;
        }

        &:nth-child(2) {
            margin: 6px 0;
        }

        &:nth-child(3) {
            transform-origin: 0% 100%;
        }
    }

    &-col {
        @media(min-width: $tablet-h) {
            display: none;
        }
    }
}

.menu-open {
    .menu-button {
        &__line {
            &:nth-child(1) {
                transform: rotate(45deg) translate(3px, -3px);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg) translate(3px, 3px);
            }
        }
    }
}