.wrapper-container {
    z-index: 50000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* background-color: grey; */
  }
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    
  }
  .sc-ion-loading-ios-h {

    color: #000;
    font-size: 14px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    outline: none;
    font-family: var(--ion-font-family, inherit);
    contain: strict;
    touch-action: none;
    user-select: none;
  }

  .back {
    background-color: rgba(0, 0, 0, 0);
    inset: 0px;
    display: block;
    position: absolute;
    transform: translateZ(0px);
    contain: strict;
    opacity: 0.01;
    opacity:1;
    touch-action: none;
    z-index: 2;
  }

  .loading-wrapper.sc-ion-loading-ios {
      display: flex;
      align-items: inherit;
      justify-content: inherit;
      width: auto;
      min-width: auto;
      max-width: 270px;
      height: auto;
      min-height: var(--min-height);
      max-height: 90%;
      background: #f9f9f9;
      opacity: 0;
      opacity: 1;
      z-index: 10;
      border-radius: 8px;
      -webkit-padding-start: 34px;
      padding-inline-start: 34px;
      -webkit-padding-end: 34px;
      padding-inline-end: 34px;
      padding-top: 24px;
      padding-bottom: 24px;
  }

  .loading-spinner {
    width: 30px;
    height: 30px;
  }

  .loading-content.sc-ion-loading-ios {
    margin-left: 16px;
    font-weight: bold;
  }
  .ion-spinner.sc-ion-loading-ios {
    color: grey;
  }
