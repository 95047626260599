.statistics {
    &-legend {
        display: flex;
        justify-content: center;
        margin-top: 8px;

        &__item {
            padding: 0 20px;
            position: relative;

            &:before {
                content: '';
                height: 1px;
                width: 14px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: currentColor;
            }

            &:after {
                content: '';
                height: 5px;
                width: 5px;
                position: absolute;
                top: 50%;
                left: 5px;
                transform: translateY(-50%);
                background-color: currentColor;
                border-radius: 50%;
            }
        }
    }

    &-timeline {
        display: flex;
        align-items: center;
        user-select: none;
        padding-top: 4px;

        &__item {
            flex-grow: 1;
            color: #3b3b3b;
            font-size: 12px;
            font-weight: 300;
            line-height: 1.375;
            white-space: nowrap;
        }

        &__day {
            display: block;
        }
        &__date {
            display: block;
            color: #92949c;
        }
    }
}

.recharts-responsive-container,
.recharts-wrapper {
    height: auto !important;
}

.recharts-brush {
    rect {
        fill: #E6E7EA;
        stroke: none;
    }
    &-traveller {
        rect {
            fill: #92949C;
        }
    }
}

.recharts-legend-wrapper {
    position: unset !important;
}

.recharts-default-legend {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    .recharts-legend-item {
        margin-right: 24px !important;
        &:last-child {
            margin-right: 0 !important  ;
        }

        svg {
            height: 11px;
            width: 11px;
            margin-right: 4px;
        }
    }
}

.recharts-legend-item-text {
    color: var(--color-gray-19) !important;
    font-size: 13px;
    line-height: 12px;
}

.custom-tooltip {
    padding: 12px 16px;
    font-size: 12px;
    line-height: 16px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-30);
    border-radius: 16px;

    &__label {
        margin: 0;
        padding: 0 0 8px;
        color: var(--color-gray-19);
        font-size: 14px;
        line-height: 17px;
    }

    .tooltip-legend {
        &__item {
            margin-top: 6px;
            position: relative;
            padding-left: 16px;
            color: var(--ion-color-medium);
            font-size: 12px;
            line-height: 12px;
            &:first-child {
                margin-top: 0;
            }

            &:before {
                content: '';
                height: 11px;
                width: 11px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: var(--color);
            }
        }
    }
}

.charts-list {
    padding: 24px 0;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }

    &__item {
        &:not(:first-child) {
            padding-top: 49px;
        }
    }

    &__title {
        padding-bottom: 16px;
        color: var(--color-gray-15);
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
    }
}

.hide-brush-texts {
    .recharts-brush-texts {
        display: none;
    }
}