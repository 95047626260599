* {
    outline: none;
}

html {
    cursor: default;
}

.sr,
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
    &--md {
        max-width: 650px;
    }
    &--xs {
        max-width: 320px;
    }
    &--400 {
        max-width: 400px;
    }
}

ion-content {
    min-width: 375px;
    font-size: 14px;
    line-height: 20px;
    --color: var(--color-gray-6);
    --offset-bottom: 0;
    --offset-top: 0;

    &::part(scroll) {
        overflow: auto;
    }
}

ion-footer {
    @media(max-width: $tablet-h) {
        z-index: -1;
    }
}

ion-list {
    contain: unset;
}

ion-item {
    font-size: 16px;
    overflow: visible;
    --color: var(--color-gray-9);
    --color-activated: var(--color-gray-9);
    --color-focused: var(--color-gray-9);
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --border-color: transparent;
    --background: transparent;

    &::part(native) {
        overflow: visible;
        padding-left: 0;
        --inner-padding-top: 6px;
        --inner-padding-bottom: 6px;
        --background-focused: transparent;
        --background-activated: transparent;
        --background-hover: transparent;
    }

    &.is-invalid {
        .form-input {
            border-color: var(--color-red);
        }
    }

    &:first-child {
        &::part(native) {
            --inner-padding-top: 0;
        }
    }
    &:last-child {
        &::part(native) {
            --inner-padding-bottom: 0;
        }
    }
}

ion-checkbox {
    --size: 22px;
    min-width: 22px;
    --checkmark-width: 2px;
    --background: var(--color-gray-8);
    --border-color: transparent;
    --transition: 250ms;
}

ion-radio {
    min-width: 22px;

    &::part(container) {
        width: 24px;
        height: 24px;
        border: 1px solid var(--color-gray-9);
        border-radius: 100%;
        transition: border-color var(--transition-250), background-color var(--transition-250);
    }

    &::part(mark) {
        margin-bottom: 2.5px;
    }

    &.radio-checked {
        &::part(container) {
            background-color: var(--color-green);
            border-color: var(--color-green);
        }

        &::part(mark) {
            border-color: var(--color-white);
        }
    }
}

ion-back-button {
    display: block;
    width: 14px;
    padding-left: 8px;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        height: 30px;
        width: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

ion-title {
    font-size: 16px;
    font-weight: 400;
    padding-left: 32px;
    padding-right: 32px;
}

ion-text {
    display: block;
    h1, h2 {
        margin: 0;
        padding: 0;
        color: var(--ion-color-primary);
        font-size: 22px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: .469px;
    }

    ul {
        margin: 14px 0;
        padding: 0 0 0 16px;
        list-style: disc outside;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        li {
            padding-top: 5px;
            &:first-child {
                padding-top: 0;
            }
        }

        a {
            text-decoration: none;
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

ion-icon {
    min-width: 24px;
    font-size: 24px;
    margin-right: 6px;
    &:last-child {
        margin-right: 0;
    }
}

ion-toggle {
    height: 28px;
    --handle-height: 24px;
    --handle-width: 24px;
    --handle-spacing: 2px;

    &::part(track) {
        height: 28px;
        width: 48px;
    }
}

ion-label {
    &.no-margin {
        margin: 0;
    }
}

ion-segment-button {
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-gray-15);
    font-weight: 400;
    --indicator-transition: var(--transition-250);
    --transition: var(--transition-250);
    --background: transparent;
    --background-checked: transparent;
    --color-checked: white;
    --indicator-color : var(--color-green);

    &.segment-button-checked {
        color: white;
    }
}

ion-toast {
    --border-radius: 10px;
    --start: 16px;
    --end: 16px;
}

ion-datetime {
    padding: 0;
    &::part(text) {
        color: var(--color-gray-6);
        font-size: 16px;
        font-weight: 400;
    }
}

ion-modal {
    .ion-page {
        justify-content: flex-start;
        overflow: auto;
    }

    &.modal--sm {
        &::part(content) {
            max-width: 480px;
        }
    }
}

ion-text {
    color: var(--color-gray-9);
    font-size: 14px;
    font-family: var(--open-sans);
    line-height: 21px;
}

ion-toolbar {
    overflow: visible !important;
    --background: transparent;

    &.bordered {
        padding-top: 20px;
        border-top: 1px solid var(--color-gray-700);
    }
}

ion-select {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-21);
    border-radius: 8px;
    flex-grow: 1;

    &::part(container) {
        width: 100%;
        padding: 2px 12px 0;
    }

    &::part(text) {
        flex-grow: 1;
        color: var(--color-gray-29);
        font-size: 14px;
        font-weight: 400;
    }

    &::part(placeholder) {
        color: hsl(0, 0%, 20%);
        opacity: .5;
    }

    &::part(icon) {
        width: 20px;
        opacity: .5;
    }
    &::part(label) {
        height: 0;
        width: 0;
        position: absolute;
        overflow: hidden;
    }
}

.toolbar-container {
        opacity: .1;
    }

.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 90%;

    &--alt {
        align-items: center;
    }
}

.title {
    display: flex;
    margin: 0 0 32px;
    padding: 0;
    font-size: 30px;
    font-weight: 300;
    font-family: var(--open-sans);
    color: var(--color-gray-9);
    line-height: 32px;
    letter-spacing: .64px;
    @media(max-width: $tablet-v) {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 400;
    }

    svg {
        flex-shrink: 0;
        margin: 4px 12px 0 0;
    }
}

.text {
    &-red {
        color: var(--color-red);
    }

    &-green {
        color: var(--color-green);
    }

    &-purple {
        color: var(--color-primary);
    }

    &-black {
        color: var(--color-black);
    }

    &-grey {
        color: var(--ion-color-medium-shade);
        &.unactive {
            color: var(--color-gray-23);
        }
    }

    &-bold {
        font-weight: bold;
    }

    &-12 {
        font-size: 12px;
    }

    &-14 {
        font-size: 14px;
    }

    &-16 {
        font-size: 16px;
    }
}

[class^="text-"] {
    transition: color var(--transition-250);
}

.fill {
    &-green {
        fill: var(--color-green);
    }

    &-red {
        fill: var(--color-red);
    }

    &-purple {
        fill: var(--color-primary);
    }

    &-light-green {
        fill: #E5F0D7;
    }

    &-light-red {
        fill: var(--color-red);
        fill-opacity: .15;
    }

    &-light-blue {
        fill: var(--color-blue);
        fill-opacity: .15;
    }

    &-light-purple {
        fill: var(--color-primary);
        fill-opacity: .15;
    }

    &-gray {
        fill: var(--color-gray-9);
    }

    &-blue {
        fill: var(--color-blue);
    }

    &-orange {
        fill: var(--color-orange);
    }
}

.icon {
    &-16 {
        height: 16px !important;
        width: 16px !important;
    }
    &-18 {
        height: 18px !important;
        width: 18px !important;
    }
    &-20 {
        height: 20px !important;
        width: 20px !important;
    }
    &-24 {
        height: 24px !important;
        width: 24px !important;
    }
    &-32 {
        height: 32px !important;
        width: 32px !important;
    }
    &-118 {
        height: 118px !important;
        width: 118px !important;
    }
}

[class^="icon-"] {
    flex-shrink: 0;
    transition: fill var(--transition-250);

    &.unactive {
        fill: var(--color-gray-28) !important;
    }
}

.invalid-message {
    display: none;
    margin-top: 6px;
    color: var(--color-red);
    font-size: 13px;
    line-height: 15px;
}

.is-invalid {
    .invalid-message {
        display: block;
    }
}

.size {
    &-14 {
        height: 14px;
        width: 14px;
    }
    &-16 {
        height: 16px;
        width: 16px;
    }
    &-20 {
        height: 20px;
        width: 20px;
    }
    &-24 {
        height: 24px;
        width: 24px;
    }
    &-80 {
        height: 80px;
        width: 80px;
    }
    &-110 {
        height: 110px;
        width: 110px;
    }
}

.mt {
    &-0 {
        margin-top: 0 !important;
    }
    &-10 {
        margin-top: 10px !important;
    }
    &-20 {
        margin-top: 20px !important;
    }
    &-32 {
        margin-top: 32px !important;
    }
}

.mb {
    &-0 {
        margin-bottom: 0 !important;
    }
    &-5 {
        margin-bottom: 5px !important;
    }
    &-10 {
        margin-bottom: 10px !important;
    }
    &-15 {
        margin-bottom: 15px !important;
    }
    &-20 {
        margin-bottom: 20px !important;
    }
    &-32 {
        margin-bottom: 32px !important;
    }
}

.ml {
    &-0 {
        margin-left: 0 !important;
    }
    &-5 {
        margin-left: 5px !important;
    }
    &-10 {
        margin-left: 10px !important;
    }
    &-15 {
        margin-left: 15px !important;
    }
    &-20 {
        margin-left: 20px !important;
    }
}

.mr {
    &-0 {
        margin-right: 0 !important;
    }
    &-5 {
        margin-right: 5px !important;
    }
    &-10 {
        margin-right: 10px !important;
    }
    &-15 {
        margin-right: 15px !important;
    }
    &-20 {
        margin-right: 20px !important;
    }
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}


.px {
    &-0 {
        padding-left: 0;
        padding-right: 0;
    }
}

.py {
    &-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.pr {
    &-0 {
        padding-right: 0 !important;
    }
    &-12 {
        padding-right: 12px !important;
    }
}

.pl {
    &-0 {
        padding-left: 0 !important;
    }
    &-12 {
        padding-left: 12px !important;
    }
}

.p-0 {
    padding: 0 !important;
}

.pt {
    &-0 {
        padding-top: 0 !important;
    }
    &-4 {
        padding-top: 4px !important;
    }
    &-8 {
        padding-top: 8px !important;
    }
    &-10 {
        padding-top: 10px !important;
    }
    &-16 {
        padding-top: 16px !important;
    }
    &-20 {
        padding-top: 20px !important;
    }
    &-32 {
        padding-top: 32px !important;
    }
    &-40 {
        padding-top: 40px !important;
    }
    &-85 {
        padding-top: 85px !important;
        @media(max-width: $phone-h) {
            padding-top: 48px !important;
        }
    }

    &-tv-32 {
        @media(max-width: $tablet-v) {
            padding-top: 32px !important;
        }
    }
}

.pb {
    &-0 {
        padding-bottom: 0 !important;
    }
    &-4 {
        padding-bottom: 4px !important;
    }
    &-8 {
        padding-bottom: 8px !important;
    }
    &-10 {
        padding-bottom: 10px !important;
    }
    &-16 {
        padding-bottom: 16px !important;
    }
    &-20 {
        padding-bottom: 20px !important;
    }
    &-32 {
        padding-bottom: 32px !important;
    }
    &-40 {
        padding-bottom: 40px !important;
    }
    &-85 {
        padding-bottom: 85px !important;
        @media(max-width: $phone-h) {
            padding-bottom: 48px !important;
        }
    }
}