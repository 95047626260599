.progress {
    height: var(--progress-size);
    position: relative;
    overflow: hidden;
    border-radius: var(--progress-size);
    background-color: var(--color-gray-18);
    --progress-size: 4px;

    &__box {
        width: 100%;
        position: relative;
        margin-bottom: 29px;
        &:last-child {
            margin-bottom: 0;
        }

        &--icon {
            padding-left: 35px;
        }

        &-icon {
            height: 22px;
            width: 22px;
            position: absolute;
            top: 4px;
            left: 0;
            fill: black;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 9px;
        color: var(--color-gray-9);
        font-size: 16px;
    }

    &__name {
        font-weight: 300;
        word-wrap: break-word;
    }

    &__buffer {
        height: 100%;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background-image: var(--progress-1);
        background-size: 385px auto;
        border-radius: var(--progress-size);
        transition: width var(--transition-500);
    }
}