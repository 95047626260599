.notifications {
    &-list {
        contain: unset;

        &__item {
            height: auto;
            margin-bottom: 16px;
            font-size: 18px;
            font-weight: 300;

            &:last-child {
                margin-bottom: 0;
            }

            &::part(native) {
                --min-height: 1px;
                --padding-top: 9px;
                --padding-bottom: 8px;
                padding-left: 24px;
                padding-right: 16px;
                box-shadow: var(--box-shadow);
                border-radius: 6px;
            }

            &::part(detail-icon) {
                display: none;
            }

            ion-icon {
                height: 19px;
                fill: var(--color-primary);
            }

            &.unread {
                color: var(--color-primary);
                font-weight: 400;
            }
        }
    }
}

.notification {
    color: var(--color-gray-15);
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;

    &__title {
        margin: 16px 0 3px;
        padding: 0;
        color: var(--ion-color-primary);
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
    }

    &__date {
        padding-bottom: 3px;
        font-size: 14px;
    }

    &__content {
        padding-top: 22px;

        h1, h2, h3, h4, h5, h6 {
            margin: 16px 0;
            color: var(--ion-color-primary);
            font-weight: 400;
            line-height: 24px;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        p {
            margin: 0 0 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}