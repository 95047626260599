.week-table {
    table-layout: fixed;
    
    th {
        padding-left: 10px;
        font-size: 16px;
        font-family: var(--open-sans);
        font-weight: 300;
        color: var(--color-gray-9);

        &:first-child {
            padding-left: 0;
        }
    }

    &__button {
        height: 36px;
        min-height: inherit;
        max-height: 36px;
        min-width: 36px;
        margin: 0;
        --ion-color-base: transparent !important;
        --ion-color-base-rgb: transparent !important;
        --ion-color-contrast: transparent !important;
        --ion-color-contrast-rgb: transparent !important;
        --ion-color-shade: transparent !important;
        --ion-color-tint: transparent !important;
        @media(max-width: $phone-h) {
            height: 30px;
            max-height: 30px;
            min-width: 30px;
        }

        &::part(native) {
            width: 36px;
            padding: 0;
            font-size: 16px;
            font-family: var(--open-sans);
            font-weight: 300;
            color: var(--color-gray-9);
            border-radius: 36px;
            @media(max-width: $phone-h) {
                font-size: 12px;
            }
        }

        &.active {
            --ion-color-base: var(--color-green-300) !important;

            &::part(native) {
                color: var(--color-white);
            }
        }
    }
}