.preferences {
    &-list {
        contain: unset;

        svg {
            fill: var(--color-gray-29);
        }

        &__item {
            --min-height: inherit;
            --padding: 12px 0 0;
            --padding-end: 0;
            --inner-padding-bottom: 0 !important;
            --inner-padding-end: 0 !important;
            overflow: visible !important;
            --background: transparent;
            color: var(--color-gray-30);
            font-weight: 300;

            &:first-child {
                --padding-top: 0;
            }

            &-start {
                padding-right: 10px;
                min-width: 145px;
            }
    
            &-end {
                display: flex;
                padding-left: 10px;
                &:empty {
                    padding-left: 0;
                }
            }
        }

        &__inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 17px 12px 10px;
            background-color: white;
            border-radius: 10px;
            box-shadow: var(--box-shadow);
        }

        &__time {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 15px;
            font-weight: 300;

            &-icon {
                height: 20px;
                width: 20px;
                min-width: 20px;
                margin-right: 4px;
            }
        }

        &__temperature {
            display: flex;
            align-items: center;
            font-size: 16px;

            &-icon {
                height: 22px;
                width: 22px;
                min-width: 22px;
                margin: 0 4px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &--editable &__inner {
            box-shadow: none;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &--editable &__item-middle {
            flex-grow: 1;
        }

        &--sm {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}